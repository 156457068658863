<template>
  <el-dialog
    title="修改备注"
    :close-on-click-modal="isClickModalClose"
    v-model="isShow"
    :width="defaultWidth"
    @close="closeModal"
  >
    <el-form
      :ref="formName"
      :model="modalData"
      :rules="ruleValidate"
      label-width="110px"
    >
      <el-form-item label="备注" prop="remark">
        <el-input type="textarea"  :autosize="{minRows:4}" placeholder="请输入备注" v-model="modalData.remark"></el-input>
      </el-form-item>
    </el-form>

    <template #footer>
      <el-button @click="closeModal"> 取消 </el-button>

      <el-button @click="submit" type="primary"> 确定 </el-button>
    </template>
  </el-dialog>
</template>

<script>
import modalMixin from "@/mixins/modalMixin";

export default {
  mixins: [modalMixin],
  emits: ["submit"],
  props: ["modalData"],
  data() {
    return {
      formName: "form",
      ruleValidate: {
        remark: [
          {
            required: true,
            message: "用户类型不能为空",
            trigger: "change",
          },
        ]
      },
    };
  },
  methods: {
    submit() {
      let modalData = this.modalData;

      let data = {
        id: modalData.id,
        remark: modalData.remark,
      };

      this.validateForm().then((res) => {
        let obj = this.deepClone(data);
        this.$emit("submit", obj);
      });
    },
  },
};
</script>
