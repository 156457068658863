<template>
  <el-dialog
    title="修改用户信息"
    :close-on-click-modal="isClickModalClose"
    v-model="isShow"
    :width="defaultWidth"
    @close="closeModal"
  >
    <el-form
      :ref="formName"
      :model="modalData"
      :rules="ruleValidate"
      label-width="110px"
    >
      <el-form-item label="套餐奖励" prop="ultimatelyPackageId">
        <el-select
          placeholder="请选择发放的套餐奖励"
          v-model="modalData.ultimatelyPackageId"
        >
          <el-Option
            v-for="(item, index) in this.productList"
            :key="index"
            :label="item.name"
            :value="item.value"
          ></el-Option>
        </el-select>
      </el-form-item>
    </el-form>

    <template #footer>
      <el-button @click="closeModal"> 取消 </el-button>

      <el-button @click="submit" type="primary"> 确定 </el-button>
    </template>
  </el-dialog>
</template>

<script>
import modalMixin from "@/mixins/modalMixin";

export default {
  mixins: [modalMixin],
  emits: ["submit"],
  props: ["modalData"],
  data() {
    return {
      formName: "form",
      productList: [
        {
          name: "139元 免费4个月",
          value: "1",
        },
        {
          name: "169元 免费5个月",
          value: "2",
        },
        {
          name: "209元 免费6个月",
          value: "3",
        },
        {
          name: "239元 免费8个月",
          value: "5",
        },
      ],
      ruleValidate: {
        ultimatelyPackageId: [
          {
            required: true,
            message: "请选择套餐奖励",
            trigger: "change",
          },
        ],
      },
    };
  },
  methods: {
    submit() {
      this.validateForm().then((res) => {
        this.$emit("submit", this.modalData);
      });
    },
  },
};
</script>
