<template>
  <div>
    <el-row :gutter="20" class="search-box">
      <el-col :span="4">
        <el-input
          placeholder="手机号码"
          v-model="query.phone"
          @change="getList(1)"
        ></el-input>
      </el-col>

      <el-col :span="4">
        <el-input
          placeholder="用户姓名"
          v-model="query.realName"
          @change="getList(1)"
        ></el-input>
      </el-col>

      <el-col :span="4">
        <el-button type="primary" @click="getList(1)">查询</el-button>
      </el-col>
    </el-row>

    <el-table
      border
      :data="dataSource"
      class="page-top-space"
      row-class-name="table-row"
    >
      <el-table-column label="姓名" prop="realName"></el-table-column>
      <el-table-column label="手机号" prop="phone"></el-table-column>
      <el-table-column label="套餐名称" prop="packageName"></el-table-column>
      <el-table-column label="创建时间" prop="createTime">
        <template #default="scope">
          <div>
            <span>{{
              this.$filter.dateFormat(new Date(scope.row.createTime))
            }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="状态" prop="status">
        <template #default="scope">
          <eb-badge :target="scope.row.status" :list="this.ACTIVE_STATUS"></eb-badge>
        </template>
      </el-table-column>
      <el-table-column label="备注" prop="remark"></el-table-column>
      <el-table-column label="操作" prop="operation">
        <template #default="scope">
          <span
            class="option option-primary"
            @click="showRemarkModal(scope.row)"
            >备注</span
          >

          <span
            v-if="scope.row.status === 0"
            class="option option-primary"
            @click="showAwardModal(scope.row)"
            >发放奖励</span
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      layout="total, prev, pager, next"
      background
      :total="total"
      :page-size="pages.pageSize"
      :current-page="pages.pageIndex"
      @current-change="getList"
    ></el-pagination>

    <RemarkModal
      :modalData="modalData"
      :ref="MODAL_KEY.REMARK_MODAL"
      @submit="submitRemark"
    ></RemarkModal>

    <SendAwardModal
      :modalData="modalData"
      :ref="MODAL_KEY.AWARD_MODAL"
      @submit="doSendAward"
    ></SendAwardModal>
  </div>
</template>


<script>
import { fetchUnicomRegList, updateUnicomRegRemark , sendAward} from "@/api/active";
import RemarkModal from "@/components/view-components/remark-modal";
import SendAwardModal from './components/SendAwardModal';

export default {
  components: { RemarkModal ,SendAwardModal},
  data() {
    return {
      ACTIVE_STATUS: [{
        name: '已登记',
        value: '0',
        style: "progress"
      },{
        name: '奖励已发放',
        value: '1',
        style: 'success'
      }],
      modalData: {},
      dataSource : [],
      MODAL_KEY: {
        REMARK_MODAL: "REMARK_MODAL",
        AWARD_MODAL:"AWARD_MODAL"
      },
      pages: {
        pageIndex: 1,
        pageSize: 10,
      },
      query: {
        phone: "",
        packageId: "",
        startTime: "",
        endTime: "",
      },
      total: 0,
    };
  },

  methods: {
    showAwardModal(row){
      this.modalData = {
        id: row.id,
        ultimatelyPackageId: row.packageId+""
      }
      this.$refs[this.MODAL_KEY.AWARD_MODAL].showModal();
    },
    doSendAward(data){
      sendAward(data).then(res=>{
        this.getList();
        this.$refs[this.MODAL_KEY.AWARD_MODAL].closeModal();
      })
    },

    submitRemark(data) {
      updateUnicomRegRemark(data).then((res) => {
        this.getList();
        this.$refs[this.MODAL_KEY.REMARK_MODAL].closeModal();
      });
    },
    showRemarkModal(row) {
      this.modalData = this.deepClone(row);
      this.$refs[this.MODAL_KEY.REMARK_MODAL].showModal();
    },

    getList(current) {
      if (!this.validatenull(current)) {
        this.pages.pageIndex = current;
      }
      fetchUnicomRegList({ ...this.query, ...this.pages }).then((res) => {
        this.total = res.data.data.total;
        this.dataSource = res.data.data.records;
      });
    },
  },
  mounted() {
    this.getList();
  },
};
</script>
